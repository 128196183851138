h2 {
  font-size: 16px;
  font-weight: 500;
}

.container {
  /* border-radius: 4px; */
  width: 100%;
  height: 100%;
  /* border: 1px solid #e6e6e6; */
  /* padding: 18px; */
}

.broker-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: space-between;
  border-radius: 4px;
  /* width: 95%; */
  height: auto;
  border: 1px solid #e6e6e6;
  padding: 18px;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  /* border: 2px solid black; */
}

.broker-left {
  /* width: 50%; */
  margin-right: 5%;
  /* border: 1px solid red; */
}

.broker-right {
  /* width: 40%; */
  /* border: 1px solid green; */
}

.broker-right h2 {
  font-size: 16px;
  margin-bottom: 16px;
}


