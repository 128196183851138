.toggleswitch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 28px; 
  display: flex;
  align-items: center;
  padding: 6px;
}

.toggleswitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -12px;
  right: 0;
  bottom: 0;
  background-color: #39A245;
  border-radius: 30px; 
  transition: .4s;
  
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px; 
  width: 12px; 
  top: 30%; 
  left: 0px; 
  transform: translate(80%, 0%); 
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}

/* input:checked + .slider {
  background-color: #39A245;
} */

input:checked + .slider:before {
  transform: translateX(65px); 
}


.statustext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #ffffff;
}
