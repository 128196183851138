.container{
    /* display: flex; */
    width: 100%;
    margin-top: 16px;
}
.optionchain-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}