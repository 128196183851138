.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ccc;
}

.left-section .add-child-account {
    background: none;
    border: none;
    /* color: #007bff; */
    font-size: 16px;
    /* cursor: pointer; */
}

.middle-section {
    display: flex;
    align-items: center;
}

/* .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
} */

/* .switch input {
    opacity: 0;
    width: 0;
    height: 0;
} */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4CAF50;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.account-type {
    margin-left: 10px;
    font-size: 16px;
}

.right-section {
    display: flex;
    align-items: center;
}

.account-search {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 4px;
   background-color: #FFFFFF;
}

.search-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: -30px;
}

.search-icon{
    cursor: pointer;
}

.broker-name {
    margin-left: 10px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    color:'#363636';
    border-radius: 4px;
    background-color: #D9D9D9;
    
}
