.app {
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "topbar"
      "navbar"
      "main";
    height: 100vh;
    background-color: #ffffff;
  }
  
  .top-bar {
    grid-area: topbar;
    background-color: #333;
    color: #fff;
    padding: 10px;
  }
  
  .nav-bar {
    grid-area: navbar;
    background-color: #555;
    /* color: #fff;
    padding: 10px; */
  }
  
  .main-content {
    grid-area: main;
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding-left: 15px;
    padding-right: 10px;
    gap: 20px;
  }
  
  .watch-lists {
    background-color: #fff;
    margin-left: 40px;
    /* padding: 20px; */
  }
  
  .home {
    background-color: #fff;
    /* padding: 20px; */
  }
  