.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.account_list {
  /* margin: 0px; */
  padding: 12px 5px;
  /* box-sizing: border-box; */
  width: 100%;
  /* margin-bottom: 10px; */
  font-size: 16px;
  font-weight: 500;
}

.broker th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f5f5f5;
  color: #817e7e;
  border: 1px solid #e6e6e6;
}

.brokerTd td {
  border: 1px solid #e6e6e6;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #363636;
  font-weight: 500;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
  /* margin: 20px 0; */
}

/* table,
th,
td {
  border: 1px solid black;
} */

/* th,
td {
  padding: 8px;
  text-align: left;
} */

.pagination {
  margin: 10px 0;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination .active {
  background-color: #007bff;
  color: white;
  border: none;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.orderTable th {
  font-size: 14px;
  color: #817e7e;
  border: 1px solid #e6e6e6;
}

.orderTabletd {
  font-size: 14px;
  color: #817e7e;
  border: 1px solid #e6e6e6;
}
.orderTable Th {
  background-color: #f5f5f5;
}

.active {
  color: #39a245;
}

.addorexit {
  /* position: absolute;
  right: 0;
  top:0; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-top: 2px;
}

.addBtn{
  background-color: #d4edda;
  color: #155724;
  font-size: 12px;
  width:45%;
  border-radius: 4px;
  height: 20px;
}

.exitBtn{
  background-color: #f8d7da;
  color: rgb(95, 11, 11);
  font-size: 12px;
  width: 45%;
  border-radius: 4px;
  height: 20px;
}
