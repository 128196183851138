.welcomeBack {
  font-size: 19px;
  font-weight: bold;
}

.heading {
  font-size: 12px;
  color: #363636;
  margin-bottom: 8px;
}

.cardStyle {
  /* borderradius: 6;
  backgroundcolor: "#FFFFFF";
  shadowcolor: "rgba(0, 0, 0, 0.1)";
  shadowradius: 0;
  shadowopacity: 1;
  borderstyle: "solid";
  borderwidth: 0.25;
  bordercolor: "rgba(129, 126, 126, 1.0)"; */
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
}



