.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f3f4f6;
  border-bottom: 1px solid #d1d5db;
}

.header .left {
  display: flex;
  align-items: center;
}

.header .icon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #374151;
  margin-right: 0.5rem;
}

.header .title {
  font-size: 1.125rem;
}

.watchlist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #d1d5db;
  border-bottom: 1px solid #d1d5db;
}

.watchlist-item .left {
  font-size: 1rem;
}

.watchlist-item .left .subtext {
  font-size: 0.875rem;
  color: #6b7280;
}

.watchlist-item .right {
  text-align: right;
}

.watchlist-item .right .price {
  color: #ef4444;
}

.watchlist-item .right .change {
  font-size: 0.875rem;
  color: #ef4444;
}

.footer {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  background-color: white;
  border-top: 1px solid #d1d5db;
}

.footer button {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: white;
}

.footer button.active {
  background-color: #d1fae5;
}

.tablebodytd {
  background-color: #f5f5f5;
  font-size: 14px;
  color: #817e7e;
  border: 1px solid #e6e6e6;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 25px;
  padding-left: 25px;
}

.tablebodytd1 {
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 25px;
  padding-left: 25px;
  /* color: #817e7e; */
  border: 1px solid #e6e6e6;
}

.targetApp {
  /* text-align: center; */
  /* margin-top: 50px; */
}

.tcontainer {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* margin-top: 20px; */
}

.tunit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6f4e7;
  border: none;
  width: 64px;
  height: 32px;
  color: #2e7d32;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
}

.tunit-button.active {
  background-color: #2e7d32;
  color: #e6f4e7;
}

.tvalue-input {
  width: 66px;
  height: 32px;
  font-size: 1.2em;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0px;
  /* padding: 2px; */
  /* margin: 0 10px; */
}

.targeth1{
  font-size: 12px;
  color: #817E7E;
  margin-bottom: 8px;
}
.tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.tooltip ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tooltip ul li {
  margin: 5px 0;
}

.input-with-tooltip input:focus + .tooltip,
.input-with-tooltip input:hover + .tooltip {
  opacity: 1;
}