.scriptsettingcontainer {
    margin-top: 41px;
    /* margin-left: 41px; */
    width: 25rem;
    border: 1px solid #e6e6e6;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 16px;
    border-radius: 4px;
}

.scriptsettingcontainer h1 {
    font-size: 16px;
    font-weight: 600;
    color:#363636
}

.scriptsettingcontainer button{
    color:#155724;
    background-color: #D4EDDA;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left:32px;
    padding-right:32px;
    margin-bottom: 16px;
    border-radius: 4px;
}

.targetApp {
    /* text-align: center; */
    /* margin-top: 50px; */
    width: "47.5%";
  }
  
  .slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: "100%";
    border: 1px solid red;
    /* margin-top: 20px; */
  }
  
  .label {
    background-color: #e6f4e7;
    padding: 10px;
    border-radius: 5px;
    font-size: 1.2em;
    color: #2e7d32;
  }
  
  .slider {
    /* -webkit-appearance: none; */
    width: 200px;
    height: 10px;
    background: #000;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  
    width: 100px;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
    border-radius: 50%;
  }

  .inputscript{
    width: "50%";
  }
  